import {download, get, post} from './index.js'
import {res} from '../../mock'

export const salConfigPage = (params) => {
  return get('/sal/config/page', params).then(res => res)
}

export const salConfig = (params) => {
    return get(`/sal/config/detail`, params).then(res => res)
}

export const editPaasInfo = (data) => {
    return post('/sal/config/edit/paasinfo', data).then(res => res)
}

/**
 * 导入商品信息
 * @param data
 * @returns {Promise<unknown>}
 */
export const uploadSalConfig = data => {
    return post('/sal/config/upload', data, { headers: { 'Content-Type': 'multipart/form-data' } }).then(res => res)
}

/**
 * 下载信息
 * @param data
 * @returns {Promise<unknown>}
 */
export const downloadSalConfig = () => {
    return download('/sal/config/download').then(res => res)
}
